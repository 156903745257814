import './FeatureCard.css'

function FeatureCard(props) {
    return (
        <div className='featureCard__main'>
            {props.children}
        </div>
    )
}

export default FeatureCard;