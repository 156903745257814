import React, { useState, useEffect } from "react";
import ReactMarkdown from 'react-markdown';
import FeatureCard from "../components/FeatureCard";
import { Link } from 'react-router-dom';
import FeatherFriendsReadme from '../FeatherFriends.md';
import SquishyFidgetReadme from '../SquishyFidget.md';
import './PrivacyPolicy.css';

export function PrivacyPolicy() {
  return (
    <>
      <FeatureCard>
        <h4>privacy policies</h4>
        <ul>
          <li><Link to='/privacy-policies/feather-friends'>link to privacy policy for Feather Friends</Link></li>
          <li><Link to='/privacy-policies/squishy-fidget'>link to privacy policy for Squishy Fidget</Link></li>
        </ul>
      </FeatureCard>
    </>
  );
}

export function FeatherFriendsPolicy() {
  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    fetch(FeatherFriendsReadme)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, []);

  return (
    <ReactMarkdown className="privacyPolicy__readme" children={markdown} />
  )
}

export function SquishyFidgetPolicy() {
  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    fetch(SquishyFidgetReadme)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, []);

  return (
    <ReactMarkdown className="privacyPolicy__readme" children={markdown} />
  )
}

export default PrivacyPolicy;