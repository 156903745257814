import FeatureCard from "../components/FeatureCard";
import './Contact.css';
import Miso from '../img/misoloaf.png';
import Somi from '../img/somiCreeping.png';

function Contact() {
  return (
    <div className='contact__container'>
      <img className='contact__miso' src={Miso} alt='a loafing orange and white cat' />
      <FeatureCard>
        <h4>contact us</h4>
        <form className='contact__form' action="https://api.web3forms.com/submit" method="POST">
          <input type="hidden" name="access_key" value="9fc9ff08-c422-48fe-9852-d75fd0a2384b" />
          <div className='contact__formContainer contact__flexwrap'>
            <div className='contact__name'>
              <label htmlFor='name'>name:</label>
              <input type='text' id='name' name='name' required />
            </div>
            <div className='contact__email'>
              <label htmlFor='email'>email:</label>
              <input type='email' id='email' name='email' required />
            </div>
          </div>
          <div className='contact__formContainer'>
            <label htmlFor='subject'>subject:</label>
            <input type='text' id='subject' name='subject' required />
          </div>
          <div className='contact__message'>
            <label htmlFor='message'>enter message:</label>
            <textarea id='message' name='message' rows='10' cols='50' required />
          </div>
          <input type="hidden" name="redirect" value="https://web3forms.com/success" />
          <button className='contact__submit' type='submit' value='submit'>submit</button>
        </form>
      </FeatureCard>
      <img className='contact__somi' src={Somi} alt='a black and white cat spying on you' />
    </div>
  );
}

export default Contact;