import './App.css';
import { React, useState } from 'react';
import { Link, Routes, Route, useLocation } from 'react-router-dom';
import Home from './routes/Home';
import About from './routes/About';
import { PrivacyPolicy, FeatherFriendsPolicy, SquishyFidgetPolicy } from './routes/PrivacyPolicy';
import Contact from './routes/Contact';
import Navigation from './Navigation.js';
import Arrow from './img/pixelarrow.png'
import Logo from './img/colorPawLogo.png'

function App() {
  const location = useLocation();
  const [hamburger, toggleHamburger] = useState(false);

  function toggleNav(e) {
    e.preventDefault();
    toggleHamburger(!hamburger);
  }

  function onPolicyPage() {
    if (location.pathname.includes('privacy-policies')) {
      const splitLocation = location.pathname.split('/');
      if (splitLocation.length > 2 && splitLocation[2]) {
        document.body.classList.add('privacyPolicy__noImage')
        return true;
      }
    }
    document.body.classList.remove('privacyPolicy__noImage')
    return false;
  }

  return (
    <>
      {!onPolicyPage() &&
        <>
          <header className='app__header'>
            <p>kittenbean studios</p>
            <img className={'app__hamburger ' + (hamburger ? 'app__hamburgerWhite' : '')} onClick={toggleNav} src={Logo} alt='kitten paw' />
            <div className={'app__linksContainer ' + (hamburger ? '' : 'hidden')}>
              <ul className='app__links'>
                <li><Link to='/' className='app__link'>home</Link>{(location.pathname === '/' || location.pathname === '') && <img className='navigation__arrow' src={Arrow} alt='arrow pointing left' />}</li>
                <li><Link to='/about' className='app__link'>about</Link>{location.pathname === '/about' && <img className='navigation__arrow' src={Arrow} alt='arrow pointing left' />}</li>
                <li><Link to='/privacy-policies' className='app__link'>privacy policies</Link>{location.pathname === '/privacy-policies' && <img className='navigation__arrow' src={Arrow} alt='arrow pointing left' />}</li>
                <li><Link to='/contact' className='app__link'>contact</Link>{location.pathname === '/contact' && <img className='navigation__arrow' src={Arrow} alt='arrow pointing left' />}</li>
              </ul>
            </div>
          </header>
          <Navigation currentPage={location.pathname} />
        </>
      }
      <Routes>
        <Route exact path='/' element={<Home />}></Route>
        <Route exact path='/about' element={<About />}></Route>
        <Route exact path='/privacy-policies' element={<PrivacyPolicy />}></Route>
        <Route exact path='/privacy-policies/feather-friends' element={<FeatherFriendsPolicy />}></Route>
        <Route exact path='/privacy-policies/squishy-fidget' element={<SquishyFidgetPolicy />}></Route>
        <Route exact path='/contact' element={<Contact />}></Route>
      </Routes>
    </>
  );
}

export default App;
