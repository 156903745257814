import "./About.css";
import SleepingCats from "../img/sleepingCats.png";
import FightingCats from "../img/catfight.png";
import FeatureCard from "../components/FeatureCard";

function About() {
  return (
    <>
      <FeatureCard>
        <h4>about us</h4>
        <p>
          Kittenbean Studios LLC is an indie game studio run by two sisters. Our
          goal is to make the cute, fun, and cozy games we've always craved.
        </p>
      </FeatureCard>
      <div className="about__team">
        <h4>the team</h4>
        <div className="about__serinus about__teamMember">
          <h5>Serinus</h5>
          <h6>Likes:</h6>
          <ul>
            <li>cats</li>
            <li>birds</li>
            <li>games</li>
          </ul>
        </div>
        <img
          className="about__sleepingCats"
          src={SleepingCats}
          alt="two cats sleeping back to back"
        />
        <div className="about__yoobs about__teamMember">
          <h5>Yoobs</h5>
          <h6>Likes:</h6>
          <ul>
            <li>cooking</li>
            <li>coziness</li>
          </ul>
        </div>
      </div>
      <img
        className="about__fightingCats"
        src={FightingCats}
        alt="two cats swiping at eachother"
      />
    </>
  );
}

export default About;
