import './Navigation.css'
import React from 'react'
import { Link } from "react-router-dom";
import Logo from './img/wordlogo.png'
import Arrow from './img/pixelarrow.png'

function Navigation(props) {
  return (
    <nav className='navigation__nav'>
      <div className='navigation__container'>
        <p className='navigation__title'>navigation</p>
        <img className='navigation__logo' src={Logo} alt='kitten paw' />
        <ul className='navigation__list'>
          <li><Link to='/' className='navigation__link'>home</Link>{(props.currentPage === '/' || props.currentPage === '') && <img className='navigation__arrow' src={Arrow} alt='arrow pointing left' />}</li>
          <li><Link to='/about' className='navigation__link'>about</Link>{props.currentPage === '/about' && <img className='navigation__arrow' src={Arrow} alt='arrow pointing left' />}</li>
          <li><Link to='/privacy-policies' className='navigation__link'>privacy policies</Link>{props.currentPage === '/privacy-policies' && <img className='navigation__arrow' src={Arrow} alt='arrow pointing left' />}</li>
          <li><Link to='/contact' className='navigation__link'>contact</Link>{props.currentPage === '/contact' && <img className='navigation__arrow' src={Arrow} alt='arrow pointing left' />}</li>
        </ul>
      </div>
    </nav>
  )
}

export default Navigation;