import './Home.css'
import miso from '../img/miso.png'
import somi from '../img/somi.png'
import apple from '../img/appstorebadge.png'

function Home() {
  return (
    <>
      <div className='home__feature'>
        <img className='home__miso' src={miso} alt='loafing orange and white cat' />
        <div className='home__featureVideo'>
          <iframe src="https://www.youtube.com/embed/TlzbMjoDZ3Q?si=2sUHy43O65MsEbaI" title=' Feather Friends Promo Video' allowFullScreen />
        </div>
      </div>
      <div className='home__featureInfo'>
        <img className='home__somi' src={somi} alt='loafing black and white cat' />
        <h4>About Feather Friends</h4>
        <p>A cute, aesthetic idle game where you attract bird friends by decorating your yard. Grow flowers in your garden to put out bird seed and decorate your front yard by buying furniture from the shop. Birds will come to visit their favorite spots and leave more gems and collectable items. In app ads can be watched to double the amount of gems and also to speed up growth in the garden. Birds that have been spotted while the user is playing will be recorded as an entry in the Field Diary. Try to spot all the birds by collecting more furniture items and upgrading your bird seed!</p>
        <div className='home__badges'>
          <a href='https://play.google.com/store/apps/details?id=com.kittenbeanstudios.featherfriends&pli=1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' /></a>
          <a href="https://apps.apple.com/us/app/feather-friends/id6472413062?itsct=apps_box_badge&amp;itscg=30200"><img src={apple} alt="Download on the App Store" /></a>
        </div>
      </div>
    </>
  );
}

export default Home;